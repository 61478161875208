import React, { useEffect, useState,Component } from "react"
import { Redirect } from "react-router-dom";
import Sticky from 'react-stickynode';
import ProductItem from "./ProductItem"
import CartItem from "./CartItem"
import withContext from "../withContext"

class ProductList extends Component{
  constructor(props) {
    super(props);
  }
 


  

  render() {
    const { products } = this.props.context
    const { user } = this.props.context
    const { cart } = this.props.context
   

   
    const cartKeys = Object.keys(cart || {})
    let total_items = 0
    let total_price = 0
    const tifOptions = Object.keys(cart).map(
      (key) => (total_items = parseInt(total_items) + parseInt(cart[key].amount))
    )
  
    const price_o = Object.keys(cart).map(
      (key) => (total_price = parseInt(total_price) + (parseInt(cart[key].amount) * parseInt(cart[key].product.price)))
    )
  return !(user) ? (
    <Redirect to="/login" />
  ) : (
    <>
      <div className='hero' style={{ backgroundColor: "#297aad" }}>
        <div className='hero-body container'>
          <h4 className='title' style={{ color: "white", fontWeight: "900" }}>
            Products
          </h4>
        </div>
      </div>
      <br />
      <div className='container'>
        <div className='columns  is-multiline'>
          <div className='column  is-multiline  is-three-fifths'>
         

            {products.data && products.data.length ? (
              products.data.map((product, index) => (
                <ProductItem
                  product={product}
                  key={index}
                  addToCart={this.props.context.addToCart}
                />
              ))
            ) : (
              <div className='column'>
                <span className='title has-text-grey-light'>
                  No products found!
                </span>
              </div>
            )}
            </div>
            <Sticky enabled={true} top={5} enableTransforms={false}>
            <div className=' column'>
           
            <h1 className='title is-4' style={{ display: 'inline-block' }}>
              Selected Items{" "}
              <span className='tag ' style={{ marginLeft: "5px" }}>
                {total_items}
              </span>
            </h1>

            <h1 className='title is-4 is-pulled-right' style={{ display: 'inline-block' }}>
              ${total_price}
            
            </h1>
            {cartKeys.length ? (
              <div className=''>
                {cartKeys.map((key) => (
                  <CartItem
                    cartKey={key}
                    key={key}
                    cartItem={cart[key]}
                    removeFromCart={this.props.context.removeFromCart}
                  />
                ))}
                <div>
                  <div className='column is-12 is-clearfix'>
                    <br />
                    <div className='is-pulled-right'>
                      <strong> Total = ${total_price}</strong>
                    </div>
                  </div>
                </div>
                <div className='column is-12 is-clearfix'>
                  <div className="field">
                    <label className="label">Bartender Name: </label>
                    <input
                      key="bartender"
                      className="input"
                      defaultValue={this.props.bartenderValue}
                      type="text"
                      onBlur={(e) => this.props.setName(e.target.value)}
                      name="bartender"
                      //onBlur={this.updateParentState}
                    />
                  </div>
                </div>
                <div className='column is-12 is-clearfix'>
                  <br />
                  <div className='is-pulled-right'>
                    <button
                      onClick={this.props.context.clearCart}
                      className='button is-warning '
                    >
                      Clear Items
                    </button>{" "}
                    <button
                      className='button is-success'
                      // onClick={() => { } }
                      onClick={this.props.context.checkout}
                    >
                      Submit Items
                    </button>
                  </div>
                </div>
              
              </div>
            ) : (
              <div className='is-two-fifths column'>
                <div className='title has-text-grey-light'>
                  No item selected!
                </div>
              </div>
                )}
            
            </div>
            </Sticky>
        </div>
      </div>
    </>
  );
}
}

export default withContext(ProductList)
