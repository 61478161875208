import React, { useEffect, useState,Component } from "react"
import { Redirect } from "react-router-dom";
import axios from "axios"
import ProductItem from "./ProductItem"
import CartItem from "./CartItem"
import withContext from "../withContext"

class OrderList extends Component{
    constructor(props) {
        super(props)
        this.state = {
            orders: [],
            total_price: null,
            total_qty: null,
        }
    } 
    
  
  async componentDidMount() {
    let user = localStorage.getItem("user")
    user = user ? JSON.parse(user) : null
    let token = user.token
    const requestOptions = {
        method: "POST",
        headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
        },
    }
    let formData = {
        user_id: user.id,
    }
    axios.post(
            "https://admin.jazzybear.co.uk/api/v2/order/user_orders",
            formData,
            requestOptions
          )
          .then((response) => {
            this.setState({orders:response.data,total_price:response.data.total_price, total_qty:response.data.total_items})
          })
          .catch(function (error) {
            return Promise.reject(error)
          })
    
  }
 
  

  render() {
   
      const { user } = this.props.context
      const orderData = this.state.orders?.data || []
   console.log(orderData)
   
  return !(user) ? (
    <Redirect to="/login" />
  ) : (
    <>
      <div className='hero' style={{ backgroundColor: "#297aad" }}>
        <div className='hero-body container'>
          <h4 className='title' style={{ color: "white", fontWeight: "900" }}>
            My Orders
          </h4>
        </div>
       </div>
        <br />
        <div className='container'>
                  <div className='columns  is-multiline'>
                      <div className="column is-two-thirds">
                <div class="table-container">
                    <table class="table">
                        <thead>
                            <tr>
                            <th>Username</th>
                            <th><abbr title="Bartender">Bartender</abbr></th>
                            <th><abbr title="Item">Item</abbr></th>
                            <th><abbr title="Quantity">Quantity</abbr></th>
                            <th><abbr title="Amount">Amount</abbr></th>
                            </tr>
                        </thead>
                        
                         <tfoot>
                            <tr>
                            <th><abbr title="Position">Total</abbr></th>
                           
                            <th></th>
                            <th></th>
                            
                                      <th>{ this.state.total_qty}</th>
                                      <th>{this.state.total_price }</th>
                            </tr>
                         </tfoot>
                              <tbody>
            {orderData && orderData.length ? (
            orderData.map((order, index) => (
                <tr>
                    <td>{ order.username}</td>
                    <td>{ order.bartender}</td>
                     <td>{order.product}</td>
                    <td>{ order.qty}</td>
                    <td>{order.price}</td>
                </tr>
            ))
            ) : (
            <div className='column'>
                <span className='title has-text-grey-light'>
                No order found!
                </span>
            </div>
            )}
        </tbody>
                    </table>
                          </div>
                          </div>
        </div>
    </div>
    </>
  );
}
}

export default withContext(OrderList)
