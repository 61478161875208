import React from "react"

const CartItem = (props) => {
  const { cartItem, cartKey } = props

  const { product, amount,price } = cartItem
  return (
    <div className=' column '>
      <div
        className='box'
        onClick={() =>
          props.removeFromCart({
            id: product.id,
          })
        }
      >
        <div className='media' style={{ alignItems: "center" }}>
          <div className='media-left'>
            <figure className='image is-64x64'>
              <img
                src='https://bulma.io/images/placeholders/128x128.png'
                
              />
            </figure>
          </div>
          <div className='media-content'>
            <b style={{ textTransform: "capitalize" }}>
              {product.name}{" "}
              {/* <span className='tag '>${product.price}</span> */}
            </b>
            {/* <div>{product.shortDesc}</div> */}
            <div>${product.price} x {`${amount}`}</div>
          </div>
          <div
            className='media-right'
            onClick={() =>
              props.removeFromCart({
                id: product.id,
              })
            }
          >
            <span className='delete is-large'></span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CartItem
