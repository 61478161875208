import React, { Component } from "react"
import { Redirect } from "react-router-dom"
import withContext from "../withContext"

class Login extends Component {
  constructor(props) {
    super(props)
    this.state = {
      username: "",
      password: "1234",
    }
  }

  handleChange = (e) =>
    this.setState({ [e.target.name]: e.target.value, error: "" })

  login = (e) => {
    e.preventDefault()

    const { username, password } = this.state
    if (!username) {
      return this.setState({ error: "Fill this field!" })
    }
    this.props.context.login(username, password).then((loggedIn) => {
      if (!loggedIn) {
        this.setState({ error: "Invalid Credentails" })
      }
    })
  }

  render() {
    return !this.props.context.user ? (
      <>
        <div className='hero ' style={{ backgroundColor: "#297aad" }}>
          <div className='hero-body container'>
            <h4 className='title' style={{ color: "white", fontWeight: "900" }}>
              Login
            </h4>
          </div>
        </div>
        <br />
        <br />
        <form onSubmit={this.login}>
          <div className='columns is-mobile is-centered'>
            <div className='column is-four-fifths'>
              <div className='field'>
                <label className='label'>User Code: </label>
                <div class='control has-icons-left has-icons-right'>
                  <input
                    className='input is-normal'
                    type='number'
                    name='username'
                    inputMode='numeric'
                    pattern='[0-9]*'
                    placeholder='Type user code'
                    onChange={this.handleChange}
                  />
                </div>
                {this.state.error && (
                  <p class='help is-danger'>{this.state.error}</p>
                )}
              </div>
              {/* <div className="field">
                <label className="label">Password: </label>
                <input
                  className="input"
                  type="password"
                  name="password"
                  onChange={this.handleChange}
                />
              </div> */}

              <div className='field is-clearfix is-centered'>
                <button className='button is-outlined is-fullwidth is-large is-info'>
                  Login
                </button>
              </div>
            </div>
          </div>
        </form>
      </>
    ) : (
      <Redirect to='/products' />
    )
  }
}

export default withContext(Login)
