import React from "react"

const ProductItem = (props) => {
  const { product } = props

  return (
    <div className=' column'>
      <div
        className='box'
        onClick={() =>
          props.addToCart({
            id: product.id,
            product,
            amount: 1,
          })
        }
      >
        <div className='media' style={{ alignItems: "center" }}>
          <div className='media-left'>
            <figure className='image is-64x64'>
              <img src={product.thumbnail_image} />
            </figure>
          </div>
          <div className='media-content'>
            <b style={{ textTransform: "capitalize" }}>
              {product.name}{" "}
              <span className='tag '>${product.price}</span>
            </b>
            {/* <div>{product.shortDesc}</div> */}
            {/* {product.stock > 0 ? (
              <small>{product.stock + " Available"}</small>
            ) : (
              <small className="has-text-danger">Out Of Stock</small>
            )} */}
          </div>
          {/* <div className='media-right'>
            <button
              className='button is-large  is-outlined    is-pulled-right'
              onClick={() =>
                props.addToCart({
                  id: product.id,
                  product,
                  amount: 1,
                })
              }
            >
              Add to Cart
            </button>
          </div> */}
        </div>
      </div>
    </div>
  )
}

export default ProductItem
