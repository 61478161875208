import React, { Component } from "react"
import { Switch, Route, Link, BrowserRouter as Router } from "react-router-dom"
import axios from "axios"
import Swal from "sweetalert2"
import AddProduct from "./components/AddProduct"
import Cart from "./components/Cart"
import Login from "./components/Login"
import ProductList from "./components/ProductList"
import OrderList from "./components/OrderList"

import Context from "./Context"

export default class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      cart: {},
      products: [],
      bartenderValue:''
    }
    this.routerRef = React.createRef()
  } 

  
  async componentDidMount() {
    let user = localStorage.getItem("user")
    let cart = localStorage.getItem("cart")

    const products = await axios.get(
      "https://admin.jazzybear.co.uk/api/v2/products"
    )
    user = user ? JSON.parse(user) : null
    cart = cart ? JSON.parse(cart) : {}

    this.setState({ user, products: products.data, cart })


    // let token = this.state.user.token

    //     const requestOptions = {
    //       method: "POST",
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //         "Content-Type": "application/json",
    //       },
    //     }
    //     let formData = {
    //       user_id: this.state.user.id,
    //     }
    // axios.post(
    //         "https://admin.jazzybear.co.uk/api/v2/order/user_orders",
    //         formData,
    //         requestOptions
    //       )
    //       .then((response) => {
    //          console.log(response)
    //       })
    //       .catch(function (error) {
    //         return Promise.reject(error)
    //       })
  }

  login = async (email, password) => {
    const res = await axios
      .post("https://admin.jazzybear.co.uk/api/v2/auth/login", {
        email,
        password,
      })
      .catch((res) => {
        return { status: 401, message: "Unauthorized" }
      })

   
    if (res.status === 200) {
      const user = {
        id: res.data.user.id,
        token: res.data.access_token,
        accessLevel: email === "admin@example.com" ? 0 : 1,
      }

      this.setState({ user })
      localStorage.setItem("user", JSON.stringify(user))
      return true
    } else {
      return false
    }
  }

  logout = (e) => {
    e.preventDefault()
    this.setState({ user: null })
    localStorage.removeItem("user")
    this.routerRef.current.history.push("/login")
  }

  addProduct = (product, callback) => {
    let products = this.state.products.slice()
    products.push(product)
    this.setState({ products }, () => callback && callback())
  }

  setName = (value) => {
    this.setState({
      ...this.state,
      bartenderValue: value
    })
   
  }

  setBartenderValue = (value) => {
    let bartenderValue = this.state.bartenderValue

    bartenderValue = value

    this.setState({ bartenderValue })
    console.log(this.state.bartenderValue)
  }

  addToCart = (cartItem) => {
    let cart = this.state.cart
    if (cart[cartItem.id]) {
      cart[cartItem.id].amount += cartItem.amount
    } else {
      cart[cartItem.id] = cartItem
    }
    // if (cart[cartItem.id].amount > cart[cartItem.id].product.stock) {
    //   cart[cartItem.id].amount = cart[cartItem.id].product.stock;
    // }
    localStorage.setItem("cart", JSON.stringify(cart))
    this.setState({ cart })
  }

  removeFromCart = (cartItemId) => {
    let cart = this.state.cart

    if (cart[cartItemId.id].amount > 1) {
      cart[cartItemId.id].amount -= 1
    } else {
      delete cart[cartItemId.id]
    }

    localStorage.setItem("cart", JSON.stringify(cart))
    this.setState({ cart })
  }

  clearCart = () => {
    let cart = {}
    localStorage.removeItem("cart")
    this.setState({ cart })
  }

  checkout = () => {
    if (!this.state.user) {
      this.routerRef.current.history.push("/login")
      return
    }
  
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, Place it!",
    }).then((result) => {
      if (result.isConfirmed) {

        let token = this.state.user.token

        const requestOptions = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
        let formData = {
          user_id: this.state.user.id,
          cart_items: this.state.cart,
          bartender:this.state.bartenderValue,
        }
        return axios
          .post(
            "https://admin.jazzybear.co.uk/api/v2/order/store",
            formData,
            requestOptions
          )
          .then((response) => {
            Swal.fire("Order Placed!", "Order has been placed successfully!", "success")
            this.clearCart();
            window.location.reload(false);
            return response
          })
          .catch(function (error) {
            return Promise.reject(error)
          }) 

        // if (!this.state.bartenderValue) {
        //   Swal.fire({
        //     position: 'top-end',
        //     icon: 'error',
        //     title: 'Bartender Name is missing!',
        //     showConfirmButton: false,
        //     timer: 1500
        //   })
        //   return
        // } else {
          
        // }
      } 
    })

    //console.log(this.state.user)
    // const products = this.state.cart.map(p => {

    //     axios.put(
    //       `http://localhost:3001/products/${p.id}`,
    //       { ...p },
    //     )

    //   return p;
    // });

   
  }

  render() {
    return (
      <Context.Provider
        value={{
          ...this.state,
          removeFromCart: this.removeFromCart,
          addToCart: this.addToCart,
          login: this.login,
          addProduct: this.addProduct,
          clearCart: this.clearCart,
          checkout: this.checkout,
        }}
      >
        <Router ref={this.routerRef}>
          <div className='App'>
            <nav
              className='navbar container'
              role='navigation'
              aria-label='main navigation'
            >
              <div className='navbar-brand'>
                <b className='navbar-item is-size-4 '>Inventory Management System</b>
                <label
                  role='button'
                  className='navbar-burger burger'
                  aria-label='menu'
                  aria-expanded='false'
                  data-target='navbarBasicExample'
                  onClick={(e) => {
                    e.preventDefault()
                    this.setState({ showMenu: !this.state.showMenu })
                  }}
                >
                  <span aria-hidden='true'></span>
                  <span aria-hidden='true'></span>
                  <span aria-hidden='true'></span>
                </label>
              </div>
              <div
                className={`navbar-menu ${
                  this.state.showMenu ? "is-active" : ""
                }`}
              >
                <Link to='/products' className='navbar-item'>
                  Products
                </Link>
                {this.state.user && (
                  <Link to='/orders' className='navbar-item'>
                    My Orders
                  </Link>
                )}
                {/* <Link to="/cart" className="navbar-item">
                  Cart
                  <span
                    className="tag is-primary"
                    style={{ marginLeft: "5px" }}
                  >
                    { Object.keys(this.state.cart).length }
                  </span>
                </Link> */}
                {!this.state.user ? (
                  <Link to='/login' className='navbar-item'>
                    Login
                  </Link>
                ) : (
                  <Link to='/' onClick={this.logout} className='navbar-item'>
                    Logout
                  </Link>
                )}
              </div>
            </nav>
            <Switch>
              <Route exact path='/' component={() => (<ProductList  />)} />
              <Route  path='/login' component={Login} />
              <Route  path='/cart' component={Cart} />
              <Route  path='/orders' component={OrderList} />
              <Route  path='/products'component={() => (<ProductList setName={this.setName} bartenderValue={this.state.bartenderValue} />)} />
            </Switch>
          </div>
        </Router>
      </Context.Provider>
    )
  }
}
